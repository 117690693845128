import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <div className="h-full flex  justify-center items-center ">
        <div className="flex flex-col">
          <h1 className="text-orange font-bold text-5xl md:text-7xl">
            404: Not Found
          </h1>
          <p className="text-black font-medium text-xl pt-4">
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
